<template>
    <div class="log_in">
        <div :class="`log_in_wrapper log_in_wrapper_media ${$store.state.sendConfirmCode ? 'd-none' : ''}`">
            <div>
                <router-link class="mobile_link" :to="{name: 'Register'}">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M15 18L9 12L15 6" stroke="#C7FF24" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    <span>Регистрация</span>
                </router-link>
                <div class="title">Вход в личный кабинет</div>
                <input-mask v-model:phone="phone" />
                <div class="text">Вы получите SMS-сообщение с кодом подтверждения для входа в личный кабинет</div>
            </div>
            <Button @btnClick="sendCode" title="Отправить код"/>
        </div>

        <!-- Identifiction -->
        <div :class="`identifiction ${!$store.state.sendConfirmCode ? 'd-none' : ''}`">
            <AuthIdentification 
                v-model:confirmCode="confirmCode" 
                :phone="phone" 
                @onPrevPage="changeToPrevPage"
                title="Введите код из сообщения" 
                description="Введите 4-значный код, который мы выслали по номеру"
            />
            <div class="identifiction_error">{{ errorMessage }}</div>
            <Button @btnClick="authLogin" :disabled="confirmCode.length < 4" title="Войти"/>
            <send-again-button @btnClick="sendCode" title="Отправить еще раз"/>
        </div>
        <!-- End identifiction -->
    </div>
</template>

<script>
import InputMask from '@/components/phone_input/InputMask.vue'
import Button from '@/components/Button.vue'
import ConfirmationInput from '@/components/ConfirmationInput.vue'
import AuthIdentification from '@/components/AuthIdentification.vue'
import SendAgainButton from '@/components/SendAgainButton.vue'

export default {
    name: "LogIn",
    components: {
        InputMask,
        Button,
        ConfirmationInput,
        AuthIdentification,
        SendAgainButton
    },
    data () {
        return {
            phone: '',
            confirmCode: '',
            errorMessage: '',
        }
    },
    methods: {
        async sendCode() {
            const { data } = await this.$http.post(
                '/v2/wallet/auth/send_code',
                { "phone": this.phone }
            );
            this.$store.commit('ConfirmCode');
        },
        async authLogin () {
            const { data } = await this.$http.post(
                '/v2/wallet/auth/login',
                { "phone": this.phone, "confirm_code": this.confirmCode },
                {
                    validateStatus: () => true
                }
            )

            if (data.status === "error") {
                this.errorMessage = data.message
            } else {
                this.$store.commit('setAuthToken', data.auth_token)
                this.$router.push('/')
            }
        },
        changeToPrevPage () {
            this.$store.commit('ConfirmCode')
        },
    }
}
</script>

<style>

</style>