<template>
    <div class="transaction_wrapper">
        <div class="title">{{title}}</div>
        <div class="transactions" v-for="(item, idx) in transactionList" :key="idx">
            <!-- <img src="@/assets/images/avatar.svg" alt=""> -->
            <div class="text">
                <div class="text_top">
                     
                    <template v-if="parseFloat(item.amount) == 0.0">
                        <div class="name">операция</div>
                        <div class="money"> {{item.amount}} {{getCurrencySymbol(item.currency_code)}}</div>                       
                    </template>
                    <template v-else-if="parseFloat(item.amount) < 0.0">
                        <div class="name">покупка</div>
                        <div class="money red">{{item.amount}} {{getCurrencySymbol(item.currency_code)}}</div>
                    </template>
                    <template v-else>
                        <div class="name">пополнение</div>
                        <div class="money">{{item.amount}} {{getCurrencySymbol(item.currency_code)}}</div>
                    </template>              

                </div>
                <div class="text_bottom">
                    <div class="path">{{item.description}}</div>
                    <div class="time">{{formatDateTime(item.created_at)}}</div>
                </div>
            </div>
        </div>
        <div v-if="transactionList.length === 0" class="transactions" >
            <div class="text">
            Совершите первый платеж и 
            в этом месте появится список Ваших транзакций.
            </div>
        </div>

    </div>
</template>

<script>
export default {
    name: "Transactions",
    props: {
        title: {
            type: String,
            default: ''
        }
    },
    data () {
        return {
            transactionList: []
        }
    },
    created() {
        this.getTransactionList()
    },
    computed: {
        authToken () {
            return this.$store.state.authToken
        }
    },
    methods: {
        async getTransactionList() {
            const { data } = await this.$http.get(
                '/v2/wallet/transaction?ordering=-created_at',
                {
                    "headers": {
                        "Authorization": "Token " + this.authToken
                    }
                }
            );
            this.transactionList = data.results;
        },
    }
}
</script>

<style>

</style>