import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import utils from './plugins/utils'
import api from './plugins/api'
import '../src/assets/style.css'
import '../src/assets/responsive.css'
import moment from 'moment'
import axios from 'axios'
import VueAxios from 'vue-axios'
import jQuery from "jquery";
const $ = jQuery;
window.$ = $;


const app = createApp(App)
    .use(moment)
    .use($)
    .use(store)
    .use(VueAxios, axios)
    .use(router)
    .use(utils)
    .use(api)


app.config.errorHandler = (err, vm, info) => {
    console.error("Error:", err);
    console.error("Vue component:", vm);
    console.error("Additional info:", info);
};

window.onerror = (message, source, lineno, colno, error) => {
    console.error('Global JavaScript Error:', message, source, lineno, colno, error);
    // store.dispatch('handleError', { err: error || message });
  };

// Global uncaught promise rejection handler
window.addEventListener('unhandledrejection', (event) => { 
    let error = event.reason;

    /*
    if (Object.hasOwn(error, 'response')) {
        if (Object.hasOwn(error.response, 'data')) {
            if (Object.hasOwn(error.response.data, 'code')) {
                if (error.response.data.code === 'INVALID_TOKEN') {
                    alert('token invalid')
                } 
            }
        }
    } else {
        console.error('Unhandled Rejection (My):', event.reason);
    }
    */
    // Render the error page component
    // renderErrorPage(event.reason.message || 'An unexpected error has occurred.');
});
  

app.mount('#app')



    