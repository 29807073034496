<template>
    <div class="wallet_save">
        <div class="top">
            <div class="title">Выберите счет</div>
            <WalletList />
            <div class="input">
                <span>Сумма </span>
                <input type="text" v-model="amount" inputmode="numeric" pattern="[0-9\s]{13,19}" placeholder="0">
            </div>
        </div>
        <Button @btnClick="TopUp()" title="Пополнить" :disabled="topUpDisabled ? '': disabled" />
        <PayModal @closeModal="closeModal()" v-if="modal_active" />
    </div>
</template>

<script>
import WalletList from '@/components/WalletList.vue'
import Button from '@/components/Button.vue'
import PayModal from '@/components/PayModal.vue'

export default {
    name: "WalletTopUp",
    components: {
        WalletList,
        Button,
        PayModal,
    },
    data () {
        return {
            amount: '',
            modal_active: false,
            topUpDisabled: false,
        }
    },
    computed: {
        authToken () {
            return this.$store.state.authToken
        },
    },    
    methods: {
        async TopUp () {
            // this.modal_active = true;
            this.topUpDisabled = true;
            
            const { data } = await this.$http.post(
                '/v2/wallet/top-up',
                {
                    "amount": this.amount,
                    "currency_code": 'RUB'
                },
                {                    
                    "headers": {
                        "Authorization": "Token " + this.authToken
                    }
                }
            ); 
            window.location = data.url;
            
            // this.topUpDisabled = false;
        },
        closeModal () {
            this.modal_active = false;
        }
    }
}
</script>

<style>

</style>