<template>
  <div>
    <router-view/>
  </div>
</template>

<script>
export default ({
  name: "App",
})
</script>

<style>
</style>
