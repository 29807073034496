async function getWalletList() {
    const { data } = await this.$http.get(
        '/v2/wallet/',
        {
            "headers": {
                "Authorization": "Token " + this.authToken
            }
        }
    );
    // this.walletList = data.results[0].accounts;
    console.log(this.walletList)
}

export default {
    install(app, options) {
        app.config.globalProperties.getWalletList2 = getWalletList;
    }
}