<template>
  <div class="main_container">
    <header class="header">
      <router-link @click="bodyRemoveOverflow()" to="/" :class="`header_profile ${notification_open ? 'header_profile_active' : ''}`">
          <img src="@/assets/images/avatar.svg" alt="">
      </router-link>
      <div v-if="this.authInfo" :class="`account_name ${notification_open ? 'account_name_active' : ''}`">
        <h2>Добро пожаловать, {{this.authInfo.first_name}}!</h2>
        <p>Хорошего дня</p>
      </div>
      <nav :class="notification_open ? 'nav_active' : ''">
        <ul class="nav_item">
          <li v-for="(item, idx) in navs" :key="idx" :class="`nav_link ${item.class} ${item.path == $route.name ? 'active' : ''}`">
            <router-link @click="bodyRemoveOverflow()" class="link" :to="{name: item.path}">
              <span v-html="item.svg"></span>
              {{item.name}}
            </router-link>
          </li>
        </ul>
      </nav>
      
      <div class="notification" to="/">
        <button @click="notification_open = !notification_open" class="notification_in">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path d="M19.3399 14.49L18.3399 12.83C18.1299 12.46 17.9399 11.76 17.9399 11.35V8.82C17.9399 6.47 16.5599 4.44 14.5699 3.49C14.0499 2.57 13.0899 2 11.9899 2C10.8999 2 9.91994 2.59 9.39994 3.52C7.44994 4.49 6.09994 6.5 6.09994 8.82V11.35C6.09994 11.76 5.90994 12.46 5.69994 12.82L4.68994 14.49C4.28994 15.16 4.19994 15.9 4.44994 16.58C4.68994 17.25 5.25994 17.77 5.99994 18.02C7.93994 18.68 9.97994 19 12.0199 19C14.0599 19 16.0999 18.68 18.0399 18.03C18.7399 17.8 19.2799 17.27 19.5399 16.58C19.7999 15.89 19.7299 15.13 19.3399 14.49Z" fill="white"/>
          <path d="M14.8297 20.01C14.4097 21.17 13.2997 22 11.9997 22C11.2097 22 10.4297 21.68 9.87969 21.11C9.55969 20.81 9.31969 20.41 9.17969 20C9.30969 20.02 9.43969 20.03 9.57969 20.05C9.80969 20.08 10.0497 20.11 10.2897 20.13C10.8597 20.18 11.4397 20.21 12.0197 20.21C12.5897 20.21 13.1597 20.18 13.7197 20.13C13.9297 20.11 14.1397 20.1 14.3397 20.07C14.4997 20.05 14.6597 20.03 14.8297 20.01Z" fill="white"/>
          </svg>
          <span class="circle_icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none">
              <circle cx="3" cy="3" r="3" fill="#C7FF24"/>
            </svg>
          </span>
        </button>

        <div v-if="notification_open" class="notifictaion_list">
          <div class="notification_head">Уведомления</div>

          <div class="notifictaion_content_wrappert">
            <div class="notification_content">
              <div class="notifictaion_item" v-for="(item, idx) in userNotifications" :key="idx">
                <div class="top">{{ formatDateTime(item.created_at) }}</div>
                <div class="bottom">
                  <img src="@/assets/images/calendar_icon.svg" alt="">
                  <div class="right">
                    <div class="right_title">
                      <!-- <div class="title">уведомление</div> -->
                      <div class="time"></div>
                    </div>
                    <right class="content">
                      {{ item.message }}
                    </right>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <button @click="notification_open = false" class="notification_foot">
            <span>Закрыть</span> 
            <img src="@/assets/images/close.svg" alt="">
          </button>
        </div>
      </div>
    </header>
  </div>
</template>
  
<script>
  export default {
    name: "Header",
    data () {
      return {
        navs: [
          {
            name: 'Главная',
            path: 'Home',
            class: '',
            svg: `<svg xmlns="http://www.w3.org/2000/svg" width="28" height="29" viewBox="0 0 28 29" fill="none">
                    <path d="M26.4045 9.42318L16.704 1.66835C15.2062 0.464536 12.7845 0.464536 11.3008 1.65436L1.60023 9.42318C0.508396 10.291 -0.191498 12.1247 0.0464656 13.4965L1.90818 24.6388C2.24413 26.6265 4.14784 28.2362 6.16354 28.2362H21.8412C23.8429 28.2362 25.7606 26.6125 26.0965 24.6388L27.9582 13.4965C28.1822 12.1247 27.4823 10.291 26.4045 9.42318ZM14.0024 19.4036C12.0707 19.4036 10.5029 17.8358 10.5029 15.9041C10.5029 13.9724 12.0707 12.4047 14.0024 12.4047C15.9341 12.4047 17.5018 13.9724 17.5018 15.9041C17.5018 17.8358 15.9341 19.4036 14.0024 19.4036Z" fill="#A6A4B4"/>
                  </svg>`
          },
          {
            name: 'Кошелек',
            path: 'Wallet',
            class: '',
            svg: `<svg xmlns="http://www.w3.org/2000/svg" width="26" height="25" viewBox="0 0 26 25" fill="none">
                    <path d="M14.1722 12.3895H7.17222C6.69388 12.3895 6.29722 11.9928 6.29722 11.5145C6.29722 11.0362 6.69388 10.6395 7.17222 10.6395H14.1722C14.6506 10.6395 15.0472 11.0362 15.0472 11.5145C15.0472 11.9928 14.6506 12.3895 14.1722 12.3895Z" fill="#A6A4B4"/>
                    <path d="M1.33887 12.3888C0.860534 12.3888 0.463867 11.9921 0.463867 11.5138V6.12386C0.463867 3.26553 2.78553 0.943848 5.64387 0.943848H12.2005C15.1989 0.943848 17.3805 2.93885 17.3805 5.68051C17.3805 6.15885 16.9839 6.55551 16.5055 6.55551C16.0272 6.55551 15.6305 6.15885 15.6305 5.68051C15.6305 3.62718 13.8455 2.69385 12.2005 2.69385H5.64387C3.75387 2.69385 2.21387 4.23386 2.21387 6.12386V11.5138C2.21387 12.0038 1.8172 12.3888 1.33887 12.3888Z" fill="#A6A4B4"/>
                    <path d="M17.6722 24.0562H6.00553C2.94887 24.0562 0.463867 21.5712 0.463867 18.5145V10.3478C0.463867 7.29115 2.94887 4.80615 6.00553 4.80615H17.6722C20.7289 4.80615 23.2139 7.29115 23.2139 10.3478V12.0394C23.2139 12.5177 22.8172 12.9144 22.3389 12.9144H21.0789C20.6705 12.9144 20.2972 13.066 20.0289 13.346L20.0172 13.3577C19.6322 13.7427 19.4805 14.2794 19.6205 14.8044C19.7955 15.4577 20.4839 15.9362 21.2539 15.9362H22.3389C22.8172 15.9362 23.2139 16.3328 23.2139 16.8112V18.5028C23.2139 21.5712 20.7289 24.0562 17.6722 24.0562ZM6.00553 6.55615C3.9172 6.55615 2.21387 8.25949 2.21387 10.3478V18.5145C2.21387 20.6028 3.9172 22.3062 6.00553 22.3062H17.6722C19.7605 22.3062 21.4639 20.6028 21.4639 18.5145V17.6978H21.2539C19.6789 17.6978 18.3139 16.7062 17.9289 15.2712C17.6255 14.1395 17.9522 12.9377 18.7805 12.121C19.3872 11.5027 20.2039 11.1644 21.0789 11.1644H21.4639V10.3478C21.4639 8.25949 19.7605 6.55615 17.6722 6.55615H6.00553Z" fill="#A6A4B4"/>
                    <path d="M23.4704 17.6969H21.2188C19.4571 17.6969 17.9638 16.3902 17.8238 14.7102C17.7304 13.7419 18.0804 12.7969 18.7804 12.1086C19.3638 11.5019 20.1921 11.1636 21.0671 11.1636H23.4588C24.6021 11.1636 25.5354 12.0852 25.5354 13.2286V15.6319C25.5471 16.7752 24.6138 17.6969 23.4704 17.6969ZM21.0789 12.9144C20.6705 12.9144 20.2972 13.066 20.0289 13.346C19.6905 13.6727 19.5271 14.1152 19.5738 14.5586C19.6321 15.3286 20.3788 15.9469 21.2188 15.9469H23.4704C23.6454 15.9469 23.7971 15.8069 23.7971 15.6319V13.2286C23.7971 13.0536 23.6454 12.9136 23.4704 12.9136L21.0789 12.9144Z" fill="#A6A4B4"/>
                  </svg>`
          },
          {
            name: 'Оплата',
            path: 'Payment',
            class: 'nav_link_payment',
            svg: `<svg xmlns="http://www.w3.org/2000/svg" width="26" height="25" viewBox="0 0 26 25" fill="none">
                    <path d="M16.3795 23.7644H19.1956C22.0005 23.7644 24.2646 21.5002 24.2646 18.6954V15.8793M24.2646 9.12069V6.30459C24.2646 3.49977 22.0005 1.23563 19.1956 1.23563L16.3795 1.23563M8.49447 1.23563L6.80481 1.23563C3.99998 1.23563 1.73584 3.49976 1.73584 6.30459L1.73584 7.99425M1.73584 15.8793L1.73584 18.6954C1.73584 21.5002 3.99998 23.7644 6.80481 23.7644H9.62091M13.0002 4.61494V20.3851M15.8163 6.86781L10.1841 6.86781C7.93125 6.86781 6.80481 7.99425 6.80481 10.2471V14.7529C6.80481 17.0057 7.93125 18.1322 10.1841 18.1322L15.8163 18.1322C18.0692 18.1322 19.1956 17.0057 19.1956 14.7529V10.2471C19.1956 7.99425 18.0692 6.86781 15.8163 6.86781Z" stroke="#A6A4B4" stroke-width="1.68966" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>`
          },
          {
            name: 'История',
            path: 'History',
            class: '',
            svg: `<svg xmlns="http://www.w3.org/2000/svg" width="22" height="27" viewBox="0 0 22 27" fill="none">
                    <path d="M10.9998 26.1133C10.0846 26.1133 9.19288 25.644 8.58275 24.8227L7.39768 23.2387C7.15128 22.9101 6.82275 22.7224 6.47075 22.6989C6.11875 22.6872 5.76675 22.8397 5.48515 23.1331L4.81635 22.5347L5.46168 23.1331C3.77208 24.94 2.46968 24.7992 1.84782 24.5528C1.21422 24.3064 0.146484 23.4968 0.146484 20.892V7.68026C0.146484 2.47066 1.64835 0.886658 6.56462 0.886658H15.435C20.3513 0.886658 21.8531 2.47066 21.8531 7.68026V20.892C21.8531 23.4851 20.7854 24.2947 20.1518 24.5528C19.5299 24.7992 18.2393 24.94 16.538 23.1331C16.2564 22.828 15.9161 22.6637 15.5406 22.6989C15.1886 22.7224 14.8483 22.9101 14.6019 23.2387L13.4169 24.8227C12.8068 25.644 11.915 26.1133 10.9998 26.1133ZM6.40035 20.9272C6.44728 20.9272 6.50595 20.9272 6.55288 20.9272C7.42115 20.9741 8.24248 21.4317 8.79395 22.1709L9.97902 23.7549C10.554 24.5176 11.434 24.5176 12.0089 23.7549L13.194 22.1709C13.7572 21.4317 14.5668 20.9741 15.4468 20.9272C16.315 20.8803 17.1833 21.244 17.8169 21.9245C18.7086 22.8749 19.2953 22.9923 19.4947 22.9101C19.7763 22.7928 20.0814 22.1123 20.0814 20.892V7.68026C20.0814 3.44452 19.3422 2.64666 15.4233 2.64666H6.56462C2.64568 2.64666 1.90648 3.44452 1.90648 7.68026V20.892C1.90648 22.124 2.21155 22.8045 2.49315 22.9101C2.69262 22.9923 3.27928 22.8749 4.17102 21.9245C4.80462 21.2792 5.59075 20.9272 6.40035 20.9272Z" fill="#A6A4B4"/>
                    <path d="M15.6932 8.51332H6.30648C5.82542 8.51332 5.42648 8.11439 5.42648 7.63332C5.42648 7.15226 5.82542 6.75332 6.30648 6.75332H15.6932C16.1742 6.75332 16.5732 7.15226 16.5732 7.63332C16.5732 8.11439 16.1742 8.51332 15.6932 8.51332Z" fill="#A6A4B4"/>
                    <path d="M14.5198 13.2067H7.47982C6.99875 13.2067 6.59982 12.8077 6.59982 12.3267C6.59982 11.8456 6.99875 11.4467 7.47982 11.4467H14.5198C15.0009 11.4467 15.3998 11.8456 15.3998 12.3267C15.3998 12.8077 15.0009 13.2067 14.5198 13.2067Z" fill="#A6A4B4"/>
                  </svg>`
          },
          {
            name: 'Аккаунт',
            path: 'Settings',
            class: 'nav_link_account',
            svg: `<svg xmlns="http://www.w3.org/2000/svg" width="26" height="25" viewBox="0 0 26 25" fill="none">
                    <path d="M16.3795 23.7644H19.1956C22.0005 23.7644 24.2646 21.5002 24.2646 18.6954V15.8793M24.2646 9.12069V6.30459C24.2646 3.49977 22.0005 1.23563 19.1956 1.23563L16.3795 1.23563M8.49447 1.23563L6.80481 1.23563C3.99998 1.23563 1.73584 3.49976 1.73584 6.30459L1.73584 7.99425M1.73584 15.8793L1.73584 18.6954C1.73584 21.5002 3.99998 23.7644 6.80481 23.7644H9.62091M13.0002 4.61494V20.3851M15.8163 6.86781L10.1841 6.86781C7.93125 6.86781 6.80481 7.99425 6.80481 10.2471V14.7529C6.80481 17.0057 7.93125 18.1322 10.1841 18.1322L15.8163 18.1322C18.0692 18.1322 19.1956 17.0057 19.1956 14.7529V10.2471C19.1956 7.99425 18.0692 6.86781 15.8163 6.86781Z" stroke="#A6A4B4" stroke-width="1.68966" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>`
          },
        ],
        notification_open: false,
      }
    },
    watch: {
      notification_open () {
        if (this.notification_open) {
          $('body').addClass('active')
        } else {
          $('body').removeClass('active');
        }
      }
    },
    created() {
      this.$store.dispatch('refreshUserNotifications')
    },
    computed: {
        authInfo () {
            return this.$store.state.authInfo
        },
        userNotifications () {
            return this.$store.state.userNotifications
        }
    },
    methods: {
      notificationOpen () {
        this.notification_open = !this.notification_open;
        $('body').removeClass('scanner_active')
      },
      bodyRemoveOverflow () {
        $('body').removeClass('scanner_active')
        this.notification_open = false;
      }
    }
  }
</script>
  

  <style>
  
  </style>