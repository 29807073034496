<template>
    <div class="register">
        <div :class="`step_head ${registre_step == 2 ? 'step_head_2' : ''}`">
            <div v-for="i in 3" :key="i" :class="`step_head_item ${i <= registre_step ? 'active' : ''}`"></div>
        </div>

        <!-- Step 1 -->
        <div :class="`step step_1 ${registre_step == 1 ? 'active' : ''}`">
            <div class="title">Регистрация</div>
            <div class="inputs">
                <Input type="text" v-model:value="user.first_name" placeholder="Ваше имя" />
                <Input type="text" v-model:value="user.last_name" placeholder="Ваша фамилия" />
                <Input type="email" v-model:value="user.email" placeholder="Email" />
                <InputMask v-model:phone="user.phone" />
            </div>
            <Button @btnClick="register()"  title="Продолжить"/>
        </div>
        <!-- End steop 1 -->

        <!-- Step 1 -->
        <div :class="`step step_2 ${registre_step == 2 ? 'active' : ''}`">
            <AuthIdentification 
                v-model:confirmCode="confirmCode" 
                :phone="user.phone" 
                @onPrevPage="changeToPrevPage" 
                title="Введите код из сообщения" 
                description="Введите 4-значный код, который мы выслали по номеру"
            />
            <Button 
                @btnClick="phoneConfirm()" 
                :disabled="confirmCode.length < 4" 
                title="Продолжить" 
            />
            <send-again-button @btnClick="sendCode()"  title="Отправить еще раз"/>
        </div>
        <!-- End steop 1 -->

        <!-- Step 1 -->
        <div :class="`step step_3 ${registre_step == 3 ? 'active' : ''}`">
            <AuthIdentification 
                v-model:confirmCode="pinCode" 
                :prevButton="false"
                title="Установите пин-код" 
                description="Придумайте 4-значный пин-код, который вы будете использовать при подтверждении транзакций"
            />
            <Button 
                @btnClick="savePinCode()"
                :disabled="pinCode.length < 4" 
                title="Продолжить" 
            />
        </div>
        <!-- End steop 1 -->
    </div>
</template>

<script>
import Input from "@/components/Input.vue"
import InputMask from "@/components/phone_input/InputMask.vue"
import Button from "@/components/Button.vue"
import AuthIdentification from '@/components/AuthIdentification.vue'
import SendAgainButton from '@/components/SendAgainButton.vue'
import { doRegister, doRegisterPhoneConfirm, doRegisterSetPin } from "@/services/api"

export default{
    name: "Register",
    components: { 
        Input,
        InputMask,
        Button,
        AuthIdentification,
        SendAgainButton,
    }, 
    computed: {
        authToken () {
            return this.$store.state.authToken
        }
    },
    data () {
        return {
            registre_step: 1,
            user: {
                first_name: '',
                last_name: '',
                email: '',
                phone: ''
            },
            confirmCode: '',
            pinCode: ''
        }
    },
    methods: {
        async sendCode() {
            const { data } = await this.$http.post(
                '/v2/wallet/auth/send_code',
                { "phone": this.phone }
            );
        },
        async register () {
            await doRegister(this.user.first_name, this.user.last_name, this.user.phone, this.user.email)
            this.registre_step = 2;
        },
        async savePinCode () {
            await doRegisterSetPin(this.authToken, this.pinCode)
            this.$router.push('/')
        },
        changeToPrevPage () {
            this.registre_step = 1;
        },
        async phoneConfirm () {
            const data = await doRegisterPhoneConfirm(this.user.phone, this.confirmCode)
            this.$store.commit('setAuthToken', data.auth_token)
            this.registre_step = 3
        }
    }
}
</script>

<style>

</style>