import axios from "axios";

function handleException(error) {
    throw error;
    
    console.log(error)

    if (Object.hasOwn(error, 'response')) {
        if (Object.hasOwn(error.response, 'data')) {
            if (Object.hasOwn(error.response.data, 'code')) {
                if (error.response.data.code === 'INVALID_TOKEN') {
                    alert('token invalid')
                } 
            }
        }
    }
}

//
// Auth
//
export async function getAuthInfo(authToken) {
    try {
        const { data } = await axios.get(
            '/v2/wallet/auth/info',
            {
                "headers": {
                    "Authorization": "Token " + authToken
                }
            }
        )        
        return data
    } catch (error) {
        handleException(error)
        return {}
    }
    
}


//
// Register
//

export async function doRegister(first_name, last_name, phone, email) {
    try {
        const { data } = await axios.post(
            '/v2/wallet/auth/register',
            {
                "first_name": first_name,
                "last_name": last_name,
                "phone": phone,
                "email": email,
            }
        )
        return data
    } catch (error) {
        handleException(error)
        return {}
    }
}

export async function doRegisterPhoneConfirm(phone, confirmCode) {
    try {
        const { data } = await axios.post(
            '/v2/wallet/auth/register/phone/confirm',
            {
                "phone": phone,
                "confirm_code": confirmCode,
            }
        )
        return data
    } catch (error) {
        handleException(error)
        return {}
    }
}


export async function doRegisterSetPin(authToken, pinCode) {
    try {
        const { data } = await axios.post(
            '/v2/wallet/auth/register/set/pin',
            {
                "pin_code": pinCode,
            },
            {
                "headers": {
                    "Authorization": "Token " + authToken
                }
            }
        )
        return data
    } catch (error) {
        handleException(error)
        return {}
    }        
}


//
// Walelt
//
export async function getWalletList(authToken) {
    try {

        const { data } = await axios.get(
            '/v2/wallet',
            {
                "headers": {
                    "Authorization": "Token " + authToken
                }
            }
        );
        
        return data.results[0].accounts;
    } catch (error) {
        handleException(error)
        return {}
    }
}



//
// Walelt user notification
//
export async function getWalletUserNotifications(authToken) {
    try {
        const { data } = await axios.get(
            '/v2/wallet/user/notification',
            {
                "headers": {
                    "Authorization": "Token " + authToken
                }
            }
        );
        
        return data.results
    } catch (error) {
        handleException(error)
        return {}
    }
}





