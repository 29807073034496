<template>
    <div class="wallet_save">
        <div class="top">
            <div class="title">Выберите счет</div>
            <WalletList />
            <div class="input">
                <span>Сумма </span>
                <input type="text" v-model="amount" inputmode="numeric" pattern="[0-9\s]{13,19}" placeholder="0">
            </div>
            <div class="input">
                <span>Карта </span>
                <input
                    type="tel"       
                    v-model="formattedCard"
                    @input="formatCard"
                    inputmode="numeric" 
                    pattern="[0-9\s]{13,19}" 
                    autocomplete="cc-number" 
                    maxlength="19" 
                    placeholder="xxxx xxxx xxxx xxxx" 
                    required>
            </div>          
            <!-- <div class="error">{{ errorMessage }}</div> -->
        </div>
        <Button @btnClick="showModal()" title="Вывести" :disabled="topUpDisabled || !card || !amount" />
        <PayModal 
            :stage="modalStage"
            :errorMessage="errorMessage"
            :sucessMessage="'Заявка на вывод средств успешно создана!'"
            @enterPin="doWithdrawal" 
            @closeModal="closeModal()"             
            v-if="modalActive" 
            :pinCode="pinCode" 
        />
    </div>
</template>

<script>
import WalletList from '@/components/WalletList.vue'
import Button from '@/components/Button.vue'
import PayModal from '@/components/PayModal.vue'

export default {
    name: "WalletTopUp",
    components: {
        WalletList,
        Button,
        PayModal,
    },
    data () {
        return {
            modalStage: 'enterPin',
            amount: '',
            card: '',
            pin: '',
            errorMessage: '',
            modalActive: false,
            topUpDisabled: false,
        }
    },
    computed: {
        authToken () {
            return this.$store.state.authToken
        },
        formattedCard: {
            get() {
                return this.card.replace(/\s+/g, '').replace(/(\d{4})/g, '$1 ').trim();
            },
            set(value) {
                this.card = value.replace(/\s/g, '');
            }
        }        
    },    
    methods: {
        formatCard() {
            this.card = this.card.replace(/\D/g, '').substring(0, 16);
        },
        async showModal () {
            this.modalActive = true;
            this.modalStage = 'enterPin';
        },
        async doWithdrawal(pinCode) {
            // this.modalActive = false;
            // this.topUpDisabled = true;
            this.modalStage = 'wating';
            
            const { data } = await this.$http.post(
                '/v2/wallet/withdrawal',
                {
                    "amount": this.amount,
                    "pan": this.card,
                    "currency_code": 'RUB',
                    "pin": pinCode,
                },
                {                    
                    "headers": {
                        "Authorization": "Token " + this.authToken
                    },
                    "validateStatus": status => (status >= 200 && status < 500)                    
                },
            );
            
            if (data.status === "error") {
                this.modalStage = 'failed';
                this.errorMessage = data.message
            } else {
                this.modalStage = 'success';
            } 
            /*
            this.topUpDisabled = false;
            
            console.log(data)
            console.log(data.status)
            if (data.status === "error") {
                this.errorMessage = data.message
            } else {
                this.modalActive = true
            } 
            */
        },
        closeModal () {
            this.modalActive = false;
        }
    }
}
</script>

<style>

</style>