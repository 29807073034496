import moment from 'moment';
import 'moment/locale/ru'; // Import Russian locale

// Set moment locale to Russian
moment.locale('ru');


export function getCurrencySymbol(currencyCode) {
  const currencySymbols = {
    'USD': '\u0024', // $
    'EUR': '\u20AC', // €
    'RUB': '\u20BD', // ₽
    'JPY': '\u00A5', // ¥
    'GBP': '\u00A3', // £
  };
  return currencySymbols[currencyCode] || '\u00A4';  // or ¤
}

export function formatDateTime(isoDatetime) {
  return moment(isoDatetime).format('LLL');
}

export function moneyPrefix(amount) {
  const parsedAmount = parseFloat(amount)

  if (parsedAmount === 0.0) {
    return ""
  } else if (parsedAmount < 0) {
    return "-"
  } else { 
    return "+"
  }
}


export default {
  install(app, options) {
      app.config.globalProperties.getCurrencySymbol = getCurrencySymbol
      app.config.globalProperties.formatDateTime = formatDateTime
      app.config.globalProperties.moneyPrefix = moneyPrefix
    }
}
