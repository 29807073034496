<template>
    <div class="payment">
        <button @click="topUp()">
            <img src="@/assets/images/money-send_2.svg" alt="">
            <span>Пополнить</span>
        </button>        
        <button @click="withdrawal()">
            <img src="@/assets/images/money-send.svg" alt="">
            <span>Вывести</span>
        </button>
    </div>
</template>

<script>
export default {
    name: "Payment",
    computed: {
        authToken () {
            return this.$store.state.authToken
        }
    },    
    methods: {
        withdrawal(){
            this.$router.push({name: "WalletWithdrawal"})
        },
        topUp() {
            this.$router.push({name: "WalletTopUp"})
        }
    }    
}
</script>

<style>

</style>