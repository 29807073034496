

export function handleAsyncError(context, fn) {
    return function (...args) {
      return fn(...args).catch((error) => {


        if (Object.hasOwn(error, 'response')) {
          if (Object.hasOwn(error.response, 'data')) {
              if (Object.hasOwn(error.response.data, 'code')) {
                  if (error.response.data.code === 'INVALID_TOKEN') {
                    console.log(context)
                      // alert('token invalid')
                    context.dispatch('logout')
                  }
              }
          }
      } else {
        console.error('Unhandled Error:', error);
      }
        // Manually trigger the global error handler 
        /*
        if (this.$root && this.$root.$options.errorHandler) {
          this.$root.$options.errorHandler(error, this, fn.name);
        } else {
          console.error('Unhandled Error:', error);
        }*/

      });
    };
  }