<template>
    <div class="history">
        <ErrorBoundary>
            <div class="history_head">
                <div class="title">История</div>
                <DateRangePicker />
            </div>
            <WalletList />
            <Payment />
            <div class="statistics">
                <img class="statistics_img" src="@/assets/images/history_degree.png" alt="">
                <img class="statistics_img statistics_img_mobile" src="@/assets/images/history_degree_mobile.png" alt="">
            </div>
            <div class="statistics_list">
                <div v-for="(item, idx) in statistics_list" :key="idx" :class="`statistics_item ${this.color(item.wallet_transaction_type)}`">
                    <div>
                        <div class="amount">{{item.amount}}</div>
                        <div class="contition">{{this.name(item.wallet_transaction_type)}}</div>
                    </div>
                    <div class="percent">{{item.percent}}%</div>
                </div>
            </div>
            <div class="main_line"></div>
            <Transactions />
        </ErrorBoundary>
    </div>
</template>

<script>
import WalletList from "@/components/WalletList.vue"
import Payment from '@/components/Payment.vue'
import Transactions from '@/components/Transactions.vue'
import DateRangePicker from '@/components/DateRangePicker.vue'
import ErrorBoundary from '@/components/ErrorBoundary.vue';


export default {
    name: "History",
    components: {
        ErrorBoundary,
        WalletList,
        Payment,
        Transactions,
        DateRangePicker
    },
    created() {
        this.getWalletStat()
    },
    computed: {
        authToken () {
            return this.$store.state.authToken
        }
    },
    data () {
        return {
            statistics_list: [
            //    {amount: '$159.45', condition: 'Пополнения', percent: '62%', class: 'green'},
            //    {amount: '$74.62', condition: 'Выводы', percent: '12%', class: 'red'},
            //    {amount: '$29.32', condition: 'Платежи', percent: '20%', class: 'yellow'},
            //    {amount: '$5.00', condition: 'Прочее', percent: '6%', class: 'blue'},
            ]
        }
    },
    methods: {
        name(wallet_transaction_type) {
            if (wallet_transaction_type === "default") {
                return "прочее"
            } 

            return "прочее"
        },
        color(wallet_transaction_type) {
            if (wallet_transaction_type === "default") {
                return "blue"
            } 

            return "blue"
        },
        async getWalletStat() {
            const { data } = await this.$http.get(
                '/v2/wallet/stat',
                {
                    "headers": {
                        "Authorization": "Token " + this.authToken
                    }
                }
            );
            this.statistics_list = data.results;            
        },
        async getTransactionList() {
            const { data } = await this.$http.get(
                '/v2/wallet/stat',
                {
                    "headers": {
                        "Authorization": "Token " + this.authToken
                    }
                }
            );
            this.transactionList = data.results;
        },
    }
}
</script>

<style>

</style>